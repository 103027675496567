import React, { useEffect, useRef, useState } from "react";
import styles from "./SectionGallery.module.scss";
import { Col, Row } from "react-bootstrap";
import { GalleryContent } from "../../App";
import axios from "axios";
import { Gallery, Image } from "react-grid-gallery";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { useScroll } from "../../helpers";

const SectionGallery = ({ storeRef, content }: SectionGalleryProps) => {
    const [images, setImages] = useState<Image[]>([]);
    const [finalImages, setFinalImages] = useState<Image[]>([]);

    const thisSection = useRef<HTMLDivElement>(null);

    const addImage = (url: Image) => {
        if (!images.includes(url)) {
            setImages(img => [...img, url])
        };
    }

    useEffect(()=>{
        storeRef(content.id, thisSection);
        if (images.length === 0) {
            content.value.images.forEach((imgID: number, i: number) => {
                axios.get(`/api/v2/images/${imgID}`).then((response) => {
                    const d = response.data;
                    const [src, w, h] = [d.meta.download_url.replaceAll('"', ''), d.width, d.height];

                    const data: Image = {
                        src: src,
                        width: w,
                        height: h
                    };
                    return data
                }).then((r) => {
                    addImage(r)
                })
            });
        }
    }, [])

    useScroll(thisSection)

    useEffect(()=>{
        const tmp = [...images];
        const mapped = Object.values(Object.fromEntries(new Map(tmp.map((img, i) => [img.src, img]))));
        if (mapped.length === content.value.images.length) {
            setFinalImages([...mapped])
        }
    }, [images])

    const [index, setIndex] = useState(-1);

    const [currentImage, setCurrentImage] = useState<Image>(finalImages[index]);
    const nextIndex = (index + 1) % finalImages.length;
    const nextImage = finalImages[nextIndex] || currentImage;
    const prevIndex = (index + finalImages.length - 1) % finalImages.length;
    const prevImage = finalImages[prevIndex] || currentImage;

    const handleClick = (index: number, item: Image) => setIndex(index);
    const handleClose = () => setIndex(-1);
    const handleMovePrev = () => setCurrentImage(prevImage);
    const handleMoveNext = () => setCurrentImage(nextImage);

    return (
        <Row className={styles.section} ref={thisSection}>
            <Col xs={12} className={styles.sectionBox}>
                <div className={styles.title}>{content.value.title}</div>
                <Row className={styles.galleryRow}>
                    <Col xs={12} className={styles.galleryCol} >
                        <Gallery images={finalImages} onClick={handleClick} margin={0} rowHeight={500} enableImageSelection={false} />
                        {index !== -1 && (
                            <Lightbox
                            mainSrc={currentImage.src}
                            imageTitle={currentImage.caption}
                            mainSrcThumbnail={currentImage.src}
                            nextSrc={nextImage.src}
                            nextSrcThumbnail={nextImage.src}
                            prevSrc={prevImage.src}
                            prevSrcThumbnail={prevImage.src}
                            onCloseRequest={handleClose}
                            onMovePrevRequest={handleMovePrev}
                            onMoveNextRequest={handleMoveNext}
                            />
                    )}
                    </Col>
                </Row>
            </Col>
        </Row>
    )
};

interface SectionGalleryProps {
    storeRef: (key: string, ref: React.RefObject<HTMLDivElement>) => void;
    content: GalleryContent;
}

export default SectionGallery;