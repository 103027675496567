import React, { useEffect, useState } from 'react';
import styles from './App.module.scss'
import axios from 'axios';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Navbar from './components/Navbar/Navbar';
import { Container } from 'react-bootstrap';
import SectionText from './components/SectionText/SectionText';
import Footer from './components/Footer/Footer';
import SectionVideo from './components/SectionVideo/SectionVideo';
import SectionGallery from './components/SectionGallery/SectionGallery';
import SectionContact from './components/SectionContact/SectionContact';

export interface ApiMeta {
  detail_url: string;
  first_published_at: string;
  html_url: string;
  slug: string;
  type: string;
}
export interface ApiData {
  id: number;
  title: string;
  meta: ApiMeta;
}

export interface ValueBase {
  type: string;
  id: string;
}

export interface Icon {
  icon: 'yt' | 'ig' | 'fb' | 'ln';
  link: string;
  label: string;
}

export interface Social extends ValueBase {
  value: Icon;
}

export interface TextContent extends ValueBase {
  type: 'section_text';
  value: {
    title: string;
    content: string;
    background_image: null | number;
  }
}

export interface GalleryContent extends ValueBase {
  type: 'section_gallery';
  value: {
    title: string;
    images: number[];
  }
}

export interface ContactContent extends ValueBase {
  type: 'section_contact';
  value: {
    title: string;
    content: string;
    contact_forms: Icon[];
    background_image: null | number;
  }
}

export interface VideoContent extends ValueBase {
  type: 'section_video';
  value: {
    title: string;
    background_image: null | number;
    video_id: string;
  }
}

export interface Details extends ApiData {
  content: (TextContent | GalleryContent | VideoContent | ContactContent)[];
  socials: Social[];
}

export interface RefIdentifier {
  id: string;
  ref: React.RefObject<any>;
}

export interface RefStorage {
  [key: string]: React.RefObject<any>;
}

const slug = process.env.REACT_APP_SLUG;

function App() {
  const isMobile = navigator.userAgent.includes('Mobile')
  const [jsonData, setJsonData] = useState<ApiData | undefined>(undefined);
  const [baseUrl, setBaseUrl] = useState<string>('');
  const [details, setDetails] = useState<Details | undefined>(undefined);
  const [sections, setSections] = useState<RefIdentifier[]>([]);
  
  useEffect(()=>{
    if (!jsonData) {
      axios.get(`/api/v2/pages/?slug=${slug}`).then((res) => {
        if (!!res) {
          const data = res.data;
          const pageItem = data.items[0];
          if (!window.location.toString().includes("preview")) {
            setBaseUrl(pageItem.meta.html_url);
            setJsonData(pageItem)
          } else {
            const querystring = window.location.search.replace(/^\?/, '');

            const params: {
              [key: string]: any
            } = {};

            querystring.replace(/([^=&]+)=([^&]*)/g, (m: string, key: string, value) => {
              params[decodeURIComponent(key)] = decodeURIComponent(value);
              return ""
            });

            const apiUrl = `/api/v2/page_preview/${pageItem.id}/?content_type=` + encodeURIComponent(params['content_type']) + '&token=' + encodeURIComponent(params['token']) + '&format=json';

            axios.get(apiUrl).then((r) => {
              if (!!r) {
                const d = r.data;
                if (!!!jsonData) {
                  setDetails(d)
                }
              }
            })
          }
        }
      }).catch((err)=>{
        process.env.REACT_APP_ENV === 'dev' && console.log('err', err);
      })
    }
  }, [])

  useEffect(()=>{
    if (!details && !!jsonData && baseUrl !== '') {
      axios.get(`${jsonData.meta.detail_url.replace(baseUrl, '/')}`).then((response) => {
        setDetails(response.data);
      });
    }
  }, [jsonData, baseUrl]);

  const pushRef = (key: string, ref: React.RefObject<HTMLDivElement>) => {
    setSections(sections => [...sections, { id: key, ref: ref}]);
  }

  return (
    <Container className={styles.body}>
      <Row className={styles.whole}>
      {!!details && <>
          <Col xs={2} sm={1} className={styles.nav}>
            <Navbar isMobile={isMobile} details={details} sections={sections} />
          </Col>

          <Col xs={10} sm={11} className={styles.content}>
            <Row>
              <Col xs={12}>
                {details.content.map((content: TextContent | GalleryContent | VideoContent | ContactContent, i: number)=>{
                  let cmp = <></>;
                  switch (content.type) {
                    case "section_text":
                      cmp = <SectionText storeRef={pushRef} content={content} />
                      break;
                    case "section_gallery":
                      cmp = <SectionGallery storeRef={pushRef} content={content} />
                      break;
                    case "section_video":
                      cmp = <SectionVideo storeRef={pushRef} content={content} />
                      break;
                    case "section_contact":
                      cmp = <SectionContact storeRef={pushRef} content={content} title={details.title} />
                      break;
                    default: 
                      break;
                    }
                  return (
                    <React.Fragment key={'section' + i}>
                      {cmp}
                    </React.Fragment>
                  )
                })}
              </Col>
            </Row>
          </Col>
        </>
        }
      </Row>
      <Row>
        <Col xs={12}>
          <Footer />
        </Col>
      </Row>
    </Container>
  );
}

export default App;