import React, { useEffect, useRef, useState } from "react";
import styles from "./SectionText.module.scss";
import { Col, Row } from "react-bootstrap";
import { TextContent } from "../../App";
import axios from "axios";
import { useScroll } from "../../helpers";

const SectionText = ({ storeRef, content }: SectionTextProps) => {
    const [backgroundImage, setBackgroundImage] = useState<null | string>(null);
    const [gw, setgw] = useState<boolean>(false);

    const thisSection = useRef<HTMLDivElement>(null);
    const imageID = content.value.background_image;
    
    if (!!imageID && backgroundImage === null) {
        axios.get(`/api/v2/images/${imageID}`).then((response) => {
            if (response.data.width >= response.data.height) {
                setgw(true);
            }
            setBackgroundImage(response.data.meta.download_url)
        })
    }
    
    useEffect(()=>{
        storeRef(content.id, thisSection);
    }, [])

    useScroll(thisSection);

    return (
        <Row className={styles.section} ref={thisSection} style={{backgroundImage: `url(${backgroundImage})`}}>
            <Col xs={12} className={`${styles.sectionBox} ${!!backgroundImage ? styles.background : ''} ${!gw ? styles.backgroundTop : ''}`}>
                <div className={styles.title}>{content.value.title}</div>
                <Row className={styles.textCol}>
                    <Col xs={12} sm={10} md={6}  dangerouslySetInnerHTML={{__html: content.value.content}}>
                    </Col>
                </Row>
            </Col>
        </Row>
    )
};

interface SectionTextProps {
    storeRef: (key: string, ref: React.RefObject<HTMLDivElement>) => void;
    content: TextContent;
}

export default SectionText;