import React, { useEffect, useState } from "react"
import { Youtube, Instagram, Facebook, Linkedin, Tiktok, Envelope, Telephone, Spotify } from "react-bootstrap-icons"

export const getIcon = (iconName: string) => {
    switch (iconName) {
        case "yt":
            return <Youtube />
        case "sp":
            return <Spotify />
        case "ig":
            return <Instagram />
        case "fb":
            return <Facebook />
        case "ln":
            return <Linkedin />
        case "tk":
            return <Tiktok />
        case "em":
            return <Envelope />
        case "tl":
            return <Telephone />
        default:
            return <></>
    }
}

export const useScroll = (ref: React.RefObject<HTMLDivElement>) => {

    // const [scrollAmnt, setScrollAmnt] = useState<number>(0);

    useEffect(() => {
    const threshold = 100;
    let lastScrollY = window.scrollY;
    let ticking = false;
    
    const updateScrollDir = () => {
        const scrollY = window.scrollY;
        
        if (Math.abs(scrollY - lastScrollY) < threshold) {
            ticking = false;
            return;
        }

        if (!!ref.current) {
            const rect = ref.current.getBoundingClientRect();
            const percentInView = ((rect.height + rect.top) / rect.height);     
            ref.current.style.backgroundPositionY = `calc(10% * ${percentInView})`
        }

        lastScrollY = scrollY > 0 ? scrollY : 0;
        ticking = false;
    };

    const onScroll = () => {
        if (!ticking) {
            window.requestAnimationFrame(updateScrollDir);
            ticking = true;
        }
    };

    window.addEventListener("scroll", onScroll);
    
    return () => window.removeEventListener("scroll", onScroll);
    });
}