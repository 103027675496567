import React, { FormEventHandler, useEffect, useRef, useState } from "react";
import styles from "./SectionContact.module.scss";
import { Button, Col, Form, Row } from "react-bootstrap";
import { ContactContent, Icon } from "../../App";
import axios from "axios";
import { getIcon, useScroll } from "../../helpers";

const SectionContact = ({ storeRef, content, title }: SectionContactProps) => {
    const [backgroundImage, setBackgroundImage] = useState<null | string>(null);
    const [gw, setgw] = useState<boolean>(false);

    const thisSection = useRef<HTMLDivElement>(null);
    const imageID = content.value.background_image;
    
    if (!!imageID && backgroundImage === null) {
        axios.get(`/api/v2/images/${imageID}`).then((response) => {
            if (response.data.width >= response.data.height) {
                setgw(true);
            }
            setBackgroundImage(response.data.meta.download_url)
        })
    }
    
    useEffect(()=>{
        storeRef(content.id, thisSection);
    }, [])

    const name = useRef('');
    const email = useRef('');
    const subject = useRef('');
    const text = useRef('');

    const [message, setMessage] = useState<{message: string | undefined, type: 'message' | 'error'} | undefined>(undefined);

    const manageMail = () => {
        setMessage(undefined);
        const data = {
            name: name.current,
            email: email.current,
            subject: subject.current,
            text: text.current
        };

        axios.post('/api/send-email/', data).then((r)=>{
            if (r.data.code !== 200) {
                setMessage({message: r.data.message, type: 'error'});
            } else {
                setMessage({message: 'Wysłano', type: 'message'})
            }
        }).catch((err) => {
            setMessage({message: 'Wystąpił błąd przy wysyłaniu: spróbuj ponownie później.', type: 'error'});
            console.log(err);
        })
    };
    
    useScroll(thisSection)

    return (
        <Row className={styles.section} ref={thisSection} style={{backgroundImage: `url(${backgroundImage})`}}>
            <Col xs={12} className={`${styles.sectionBox} ${!!backgroundImage ? styles.background : ''} ${!gw ? styles.backgroundTop : ''}`}>
                <div className={styles.title}>{content.value.title}</div>
                <Row className={styles.contactRow}>
                    <Col xs={6} className={styles.socialsCol}>
                        <Row>
                            <Col xs={12}>
                                {title}
                            </Col>
                        </Row>
                        {content.value.contact_forms.map((cf: Icon) => {
                            return <Row key={'icon' + Math.random() * 10}>
                                <Col xs={12} className={styles.socialRow}>
                                    <div className={styles.icon}>
                                        {!!cf.link ?
                                            <a href={cf.link}>{getIcon(cf.icon)}</a> :
                                            <div>{getIcon(cf.icon)}</div>
                                        }
                                    </div>
                                    <div className={styles.link}>
                                        {!!cf.link ?
                                            <a target="_blank" rel="noreferrer" href={cf.link}>{cf.label}</a> :
                                            <div>{cf.label}</div>
                                        }
                                    </div>
                                </Col>
                            </Row>
                        })}
                    </Col>
                    <Col xs={6} className={styles.formCol}>
                        <Row>
                            <Col xs={12}>
                                <Form onSubmit={ (e: any) => {
                                    e.preventDefault();
                                    manageMail();
                                }}>
                                    <Form.Group>
                                        <Row>
                                            <Col id="data" xs={12} className={`${styles.group} ${!!(message?.type === 'error') && styles.error}`}>
                                                <Form.Control onChange={(e) => {
                                                    name.current = e.currentTarget.value
                                                }} placeholder="imię i nazwisko" type="name" required />
                                                <Form.Control onChange={(e) => {
                                                    email.current = e.currentTarget.value
                                                }} placeholder="email" type="email" required />
                                                <Form.Control onChange={(e) => {
                                                    subject.current = e.currentTarget.value
                                                }} placeholder="temat" required />
                                                <Form.Control onChange={(e) => {
                                                    text.current = e.currentTarget.value
                                                }} placeholder="treść" as="textarea" rows={10} required />
                                            </Col>
                                        </Row>
                                        <Row style={{marginBottom: "16px"}}>
                                            <Col xs={12} sm={6} md={3} className={`${styles.group} ${!!(message?.type === 'error') && styles.error}`}>
                                                <Button type="submit" className={styles.btn}>wyślij</Button>
                                            </Col>
                                            {!!message && <Col xs={12} sm={6} md={9} className={`${styles.msg} ${message.type === 'error' && styles.errMsg}`}>
                                                {message.message}
                                            </Col>}
                                        </Row>
                                    </Form.Group>
                                </Form>
                            </Col>
                        </Row>
                    </Col>

                </Row>
            </Col>
        </Row>

    )
};
interface SectionContactProps {
    storeRef: (key: string, ref: React.RefObject<HTMLDivElement>) => void;
    content: ContactContent;
    title: string;
}

export default SectionContact;