import React from "react";
import styles from "./Footer.module.scss"
import { Col, Row } from "react-bootstrap";

const Footer = ({ }: FooterProps) => {

    return (
        <Row className={styles.footerRow}>
            <Col xs={12} md={6} className={styles.copyrights}>
                <strong>copyrights:</strong> Małgorzata Skrzyniarz
            </Col>
            <Col xs={12} md={6}>
                <Row>
                    <Col xs={12} className={styles.workers}>
                        <strong>projekt:</strong> anarchia.studio
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} className={styles.workers}>
                        <strong>realizacja:</strong> ash fork studio 
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} className={styles.workers}>
                        <strong>wykonanie:</strong> Dominik Wilk 
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}

interface FooterProps {  }

export default Footer;