import React, { useState } from "react";
import styles from "./Navbar.module.scss"
import { Col, Row } from "react-bootstrap";
import { List } from "react-bootstrap-icons";
import { Details, RefIdentifier } from "../../App";
import Navigation from "../Navigation/Navigation";
import { getIcon } from "../../helpers";

const Navbar = ({ details, sections }: NavbarProps) => {
    const [navOpen, setNavOpen] = useState<boolean>(false);

    return (
        <Row className={styles.navRow}>
            <Col xs={12} className="px-0">
                <Row style={{position: "sticky", top: '0px'}}>
                    <Col xs={12} className={styles.icon}>
                        <List onClick={() => setNavOpen(true)} />
                    </Col>
                </Row>
                <Row style={{position: "sticky", top: '15vh'}}>
                    <Col xs={12} className={styles.title}>
                        <div>
                            {details.title}
                        </div>
                    </Col> 
                </Row>
            </Col>

            {!!navOpen && 
            <div className={styles.navContainer}>
                {details.socials.map((el, i) => {
                    const icon = getIcon(el.value.icon)
                    return (
                        <a key={String(Math.random() * i).slice(0, 12)} target="_blank" href={el.value.link} className={styles.social} style={{top: 56 * i + 20 + 'px', fontSize: '26px'}}>
                            {icon}
                        </a>
                    )
                })}
                <Navigation details={details} closeMe={() => setNavOpen(false)} sections={sections} />
            </div>
            }
        </Row>
    )
}

interface NavbarProps {
    isMobile: boolean;
    details: Details;
    sections: RefIdentifier[];
}

export default Navbar;