import React, { useEffect, useRef, useState } from "react";
import styles from "./SectionVideo.module.scss";
import { Col, Row } from "react-bootstrap";
import { VideoContent } from "../../App";
import axios from "axios";
import { useScroll } from "../../helpers";

const SectionVideo = ({ storeRef, content }: SectionVideoProps) => {
    const [backgroundImage, setBackgroundImage] = useState<null | string>(null);

    const thisSection = useRef<HTMLDivElement>(null);
    const imageID = content.value.background_image;
    
    if (!!imageID && backgroundImage === null) {
        axios.get(`/api/v2/images/${imageID}`).then((response) => {
            setBackgroundImage(response.data.meta.download_url)
        })
    }
    
    useEffect(()=>{
        storeRef(content.id, thisSection);
    }, [])

    useScroll(thisSection)

    return (
        <Row className={styles.section} ref={thisSection} style={{backgroundImage: `url(${backgroundImage})`}}>
            <Col xs={12} className={`${styles.sectionBox} ${!!backgroundImage ? styles.background : ''}`}>
                <div className={styles.title}>{content.value.title}</div>
                <Row className={styles.videoRow}>
                    <Col xs={12} className={styles.video}>
                        <iframe 
                        src={`https://www.youtube.com/embed/${content.value.video_id}` }
                        title="YouTube video player" 
                        allow="
                        accelerometer;
                        autoplay; 
                        clipboard-write; 
                        encrypted-media; 
                        gyroscope; 
                        picture-in-picture; 
                        web-share
                        "
                        width="560" 
                        height="315" 
                        />
                    </Col>
                </Row>
            </Col>
        </Row>
    )
};

interface SectionVideoProps {
    storeRef: (key: string, ref: React.RefObject<HTMLDivElement>) => void;
    content: VideoContent;
}

export default SectionVideo;