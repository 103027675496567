import React, { useEffect, useState } from "react";
import styles from "./Navigation.module.scss"
import { Col, Row } from "react-bootstrap";
import { XLg } from "react-bootstrap-icons";
import { Details, RefIdentifier, RefStorage } from "../../App";

const Navigation = ({ details, closeMe, sections }: NavigationProps) => {
    const [show, setShow] = useState<boolean>(false);

    useEffect(()=>{
        if (!show) {
            setTimeout(() => setShow(true), 10);
        }
    }, [])

    return (
        <Col xs={12} className={`${styles.navigationBox} ${!!show ? styles.open : ''}`}>
            <Row className={styles.titleBox}>
                <Col xs={3} sm={2} md={1} className={styles.close}>
                    <XLg onClick={closeMe} />
                </Col>
                <Col xs={12} className={styles.title}>
                    {details.title}
                </Col>
            </Row>
            <Row className={styles.links}>
                <Col xs={12}>
                    {details.content.map((c, i)=>{
                        const section = sections.find(s => s.id === c.id);
                        if (!!section) {
                            return <Row key={'menuEl' + i}>
                                <Col xs={12} className={styles.element}>
                                    <p onClick={()=>{
                                        if (!!section) {
                                            closeMe();
                                            section.ref.current.scrollIntoView();
                                        }
                                    }}>
                                        {c.value.title}
                                    </p>
                                </Col>
                            </Row>
                        } else {
                            return <React.Fragment key={'ndfnd' + i}></React.Fragment>
                        }
                    })}
                </Col>
            </Row>
        </Col>
    )
}

interface NavigationProps {
    details: Details;
    closeMe: () => void;
    sections: RefIdentifier[];
}

export default Navigation;